svg{
    shape-rendering: geometricPrecision;
    text-rendering: geometricPrecision;
    image-rendering: optimizeQuality;
    fill-rule: evenodd;
    clip-rule: evenodd;
    width: 100%;
    height: 100%;
}
 

.air2 {
    fill:#FEFEFE;
    transform-origin: right;
    transform: scaleX(0);
}
#air:hover .air2 {
    fill:#FEFEFE;
    transform-origin: right;
    animation: wipe 2s linear infinite;
}
@keyframes wipe{
    0%{transform: scaleX(1);}
    25%{transform-origin: right; transform: scaleX(0);}
    50%{transform-origin: left; transform: scaleX(0);}
    75%{transform-origin: left; transform: scaleX(0);}
    100%{transform-origin: left; transform: scaleX(1);}
}
.air3 {fill:#EE3341;}
.air1 {fill:#1B75BC;fill-rule:nonzero}
.air0 {fill:#EE3341;fill-rule:nonzero}



.barrage2 {fill:#40C8E2}
.barrage1 {fill:#414143}
.barrage0 {fill:#636466}
.barrage5 {fill:#373435;fill-rule:nonzero}
.barrage3a,.barrage3b,.barrage3c,.barrage3d {
    fill:white;fill-rule:nonzero;
    animation: fall 1s linear infinite;
    animation-play-state: paused;
}
#barrage:hover .barrage3a, #barrage:hover .barrage3b, #barrage:hover .barrage3c, #barrage:hover .barrage3d{
    animation-play-state: running;
}
#barrage:hover .barrage3b{
    animation-delay: 0.3s;
}
#barrage:hover .barrage3c{
    animation-delay: 0.6s;
}
#barrage:hover .barrage3d{
    animation-delay: 0.9s;
}
@keyframes fall{
    0%{transform: translateY(-5%); opacity: 0;}
    25%{transform: translateY(-5%); opacity: 0;}
    50%{transform: translateY(5%); opacity: 1;}
    75%{transform: translateY(10%); opacity: 0;}
    100%{transform: translateY(10%); opacity: 0;}
}
.barrage6 {
    fill:url(#id0);
    transform-origin: 92% 0%;
}
#barrage:hover .barrage6{
    transform: scaleY(0.3);
    animation: measure 0.2s infinite;
}
@keyframes measure{
    0%{transform: scaleY(0.3);}
    25%{transform: scaleY(0.25);}
    50%{transform: scaleY(0.29);}
    75%{transform: scaleY(0.25);}
    100%{transform: scaleY(0.3);}
}
.barrage4 {fill:url(#barid1)}

.weather0 {fill:#FCFCCA}
.weather1 {fill:url(#barid7)}
.weather2 {fill:url(#barid8)}
.weather9 {fill:url(#barid0)}
.weather7 {fill:url(#barid1)}
.weather8 {fill:url(#barid2)}
.weather5 {fill:url(#barid3)}
.weather4 {fill:url(#barid4)}
.weather3 {fill:url(#barid5)}
.weather6 {fill:url(#barid6)}

#weather:hover .weather0 {
    animation: sun 3s infinite ease;
    transform-origin:66% 25%;
}

@keyframes sun{
    0%{ transform: scale(1);}
    50%{ transform: scale(0.9);}
    100%{ transform: scale(1);}
}

#weather:hover .weather2 {
    transform: translateX(30%);
    opacity: 0;
    animation: cloud 3s infinite linear;
    animation-delay: 1s;
}

@keyframes cloud{
    0%,30%{ transform: translateX(30%); opacity: 0; }
    40%,90%{ transform: translateX(0px); opacity: 1; }
    100%{ transform: translateX(-30%); opacity: 0; }
}

#weather:hover .weather4, #weather:hover .weather8, #weather:hover .weather9 {
    opacity:0;
    animation: rain 3s infinite linear;
}
#weather:hover .weather7, #weather:hover .weather3 {
    opacity:0;
    animation: rain 3s infinite linear;
    animation-delay: 0.1s;
}
#weather:hover .weather6, #weather:hover .weather5 {
    opacity:1;
    animation: rain 3s infinite linear;
    animation-delay: 0.2s;
}
@keyframes rain{
    0%,80%{ transform: translateY(-50px); opacity: 0; }
    90%{ transform: translateY(0px); opacity: 1; }
    100%{ transform: translateY(50px); opacity: 0; }
}

.energy_fil4 {fill:none}
.energy_fil3 {fill:#2C9AA6;transform-origin: 50% 29.5%;transition-box: fill-box;}
#energy:hover .energy_fil3{animation: fans 1s infinite linear;}
@keyframes fans{from {transform: rotate(0deg);}to {transform: rotate(120deg);}}
.energy_fil1 {fill:#4D4D4D}
.energy_fil0 {fill:black;fill-rule:nonzero}
.energy_fil2 {fill:#F35A3B;fill-rule:nonzero}
#energy:hover .energy_fil2{animation: bulb 1s infinite linear;	}
@keyframes bulb{0% {fill: #F35A3B}50% {fill: yellow;}100% {fill: #F35A3B}}

#air, #barrage, #weather, #energy{
    cursor: pointer;
}