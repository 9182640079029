svg {
  shape-rendering: geometricPrecision;
  text-rendering: geometricPrecision;
  image-rendering: optimizeQuality;
  fill-rule: evenodd;
  clip-rule: evenodd;
}

.marker_fil2 {
  fill: black;
}
/* .marker_fil0 {fill:rgb(0,255,0)} dynamic used */
.marker_fil3 {
  fill: url(#marker_id0);
}
.marker_fil1 {
  fill: url(#marker_id1);
}
.marker_fnt0 {
  font-weight: bold;
  font-size: 5737.45px;
  font-family: "Arial";
}
